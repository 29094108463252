import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {Modal, Button} from "react-bootstrap";
import {toast} from "react-toastify";

const DeleteOrganizationIdentifierModal = ({showModal, onClose, selectedIdentifier}) => {
    const mutation = graphql`
    mutation DeleteOrganizationIdentifierModalMutation($id: ID!) {
        organization {
            deleteIdentifier(input: {id: $id}) {
                organization {
                    id
                    identifiers {
                        id
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const handleDeleteClick = () => {
        commitMutation({variables: {id: selectedIdentifier.id},
        onCompleted: (data) => {
            if(data.organization) {
                toast.success("Identifier has been deleted")
                onClose();
            }
        }})
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Identifier?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete Identifier: {selectedIdentifier.identifierType?.display} {selectedIdentifier.value}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>{isInFlight ? "Deleting" : "Delete"}</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteOrganizationIdentifierModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedIdentifier: PropTypes.object.isRequired
}
export default DeleteOrganizationIdentifierModal