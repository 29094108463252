import {Environment, Network, RecordSource, Store} from 'relay-runtime';
import {toast} from 'react-toastify'
import {removeItemFromStore} from "../helpers/utils";
async function fetchRelay(params, variables, _cacheConfig) {
    let url =  "/graphql";
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: params.text,
            variables
        }),
    });
    const json = await response.json();
    if(response.status === 401) {
        removeItemFromStore("user");
        throw new Error("Unauthorized", {cause: {status: 401}});
    }
    else if(Array.isArray(json.errors)) {
        console.log(json.errors);
        json.errors.map(e => {
            toast.error(e.message, {autoClose: false});
        })
    }
    return json;
}

export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource(), {
        gcReleaseBufferSize:10
    })
});