/**
 * @generated SignedSource<<8ce7e32d20e9da1ab8d43b6041df7c9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lineOfServiceId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Literal",
        "name": "feeScheduleDescription",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "isComplex",
        "value": "false"
      },
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "lineOfServiceId",
        "variableName": "lineOfServiceId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewContractLinesOfServiceModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LegalContractLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "addLineOfService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "legalContractLineOfService",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegalContractLineOfService",
                        "kind": "LinkedField",
                        "name": "linesOfService",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ContractLinesOfService_LinesOfService"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewContractLinesOfServiceModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LegalContractLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "addLineOfService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "legalContractLineOfService",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegalContractLineOfService",
                        "kind": "LinkedField",
                        "name": "linesOfService",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LineOfService",
                            "kind": "LinkedField",
                            "name": "lineOfService",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "otherServicesDiscountPercentage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentageOfBase",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isComplex",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb99dd615ada9f1f21f4bd7b578d98a0",
    "id": null,
    "metadata": {},
    "name": "NewContractLinesOfServiceModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation NewContractLinesOfServiceModal_Mutation(\n  $contractId: ID!\n  $lineOfServiceId: ID!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  legalContract {\n    addLineOfService(input: {legalContractId: $contractId, lineOfServiceId: $lineOfServiceId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, isComplex: \"false\", feeScheduleDescription: \"\"}) {\n      legalContractLineOfService {\n        id\n        contract {\n          id\n          linesOfService {\n            ...ContractLinesOfService_LinesOfService\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ContractLinesOfService_LinesOfService on LegalContractLineOfService {\n  id\n  lineOfService {\n    id\n    name\n  }\n  otherServicesDiscountPercentage\n  percentageOfBase\n  isComplex\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "dd41f98ddae569b3845393774aee56b4";

export default node;
