import React from 'react';
import {useLoaderData} from 'react-router-dom';
import UserInfo from "./UserInfo";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";

const UserDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query UserDetailsQuery($id: ID!) {
            user(id: $id) {
                userName
                ...UserInfo_User
            }
            allRoles {
                ...RolesFragments_AllRolesDropdown
            }
        }
    `, loader.userDetailsQuery);
    
    return (
        <>
            <DetailsPageHeader iconText={"User"} iconType={"user"} title={data.user.userName} />
            <UserInfo user={data.user} roles={data.allRoles} />
        </>
    );
};

export default UserDetails;
