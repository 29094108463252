/**
 * @generated SignedSource<<2fa152925023cad962d02ed7e66e6876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworkDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "network",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkInfo_Network"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContract",
            "kind": "LinkedField",
            "name": "contracts",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractsTable_Contract"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkOwnersFragments_AllNetworkOwnersDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NetworkDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "network",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NetworkOwner",
            "kind": "LinkedField",
            "name": "networkOwner",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContract",
            "kind": "LinkedField",
            "name": "contracts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Network",
                "kind": "LinkedField",
                "name": "network",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c7cf5e3d314e4c39fec13907a5fccf0",
    "id": null,
    "metadata": {},
    "name": "NetworkDetailsQuery",
    "operationKind": "query",
    "text": "query NetworkDetailsQuery(\n  $id: ID!\n) {\n  network(id: $id) {\n    name\n    ...NetworkInfo_Network\n    contracts {\n      ...ContractsTable_Contract\n      id\n    }\n    id\n  }\n  allNetworkOwners {\n    ...NetworkOwnersFragments_AllNetworkOwnersDropdown\n    id\n  }\n}\n\nfragment ContractsTable_Contract on LegalContract {\n  id\n  name\n  startDate\n  endDate\n  network {\n    id\n    name\n  }\n  organization {\n    id\n    primaryName\n  }\n}\n\nfragment NetworkInfo_Network on Network {\n  id\n  name\n  networkOwner {\n    id\n    name\n  }\n}\n\nfragment NetworkOwnersFragments_AllNetworkOwnersDropdown on NetworkOwner {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "ad7fc95ac5dff4ed76775c00fcdc83d7";

export default node;
