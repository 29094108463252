import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import {LinkCell, PhoneCell} from "../../components/common/advance-table/CustomCells";
import {useUser} from "../../hooks/useUser";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import NewUserModal from "./NewUserModal";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {userName, id} = rowData.row.original;
            return <LinkCell name={userName} to={"/users/" + id} hasAvatar/>
        }
    },
    {
        accessor: 'email',
        Header: 'Email'
    },
    {
        accessor: 'phoneNumber',
        Header: 'Phone',
        Cell: PhoneCell
    },
];

const Users = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query UsersQuery {
            allUsers {
                id
                userName
                email
                phoneNumber
            }
        }
    `, loader.usersQuery)
    const [showModal, setShowModal] = useState(false);
    const {hasPermission} = useUser();
    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={data.allUsers}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <IndexTableHeader table name={"Users"} onNewClick={() => {setShowModal(true)}} showNew={hasPermission("Users.Create")}/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter rowCount={data.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewUserModal showModal={showModal} onClose={() => {setShowModal(false)}} />
        </>
    );
};

export default Users;
