/**
 * @generated SignedSource<<d1b571d1ce022ffa8b3a1eff6dde6c70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractLineOfServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractLineOfService",
            "kind": "LinkedField",
            "name": "linesOfService",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LineOfService",
                "kind": "LinkedField",
                "name": "lineOfService",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractLineOfServiceInfo_ContractLineOfService"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractLineOfServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContractLineOfService",
            "kind": "LinkedField",
            "name": "linesOfService",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LineOfService",
                "kind": "LinkedField",
                "name": "lineOfService",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "contract",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isComplex",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentageOfBase",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherServicesDiscountPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feeScheduleDescription",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e79a41100e86eb2c33ca5e3f0273c2f3",
    "id": null,
    "metadata": {},
    "name": "ContractLineOfServiceDetailsQuery",
    "operationKind": "query",
    "text": "query ContractLineOfServiceDetailsQuery(\n  $id: ID!\n) {\n  legalContract(id: $id) {\n    id\n    linesOfService {\n      id\n      lineOfService {\n        name\n        id\n      }\n      ...ContractLineOfServiceInfo_ContractLineOfService\n    }\n  }\n}\n\nfragment ContractLineOfServiceInfo_ContractLineOfService on LegalContractLineOfService {\n  id\n  contract {\n    startDate\n    endDate\n    id\n  }\n  lineOfService {\n    name\n    id\n  }\n  isComplex\n  percentageOfBase\n  otherServicesDiscountPercentage\n  effectiveFrom\n  effectiveTo\n  feeScheduleDescription\n}\n"
  }
};
})();

node.hash = "75f42d5ebec933a5332a3b895aebea14";

export default node;
