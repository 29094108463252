/**
 * @generated SignedSource<<78dbf602334045cc41a4fcb40fab6180>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoleInfo_role"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RolePermissions_rolePermission"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "allPermissions",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RolePermissions_activePermission"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Permission",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "allPermissions",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39ac13a0484b5fc9ee3abaabf0bff197",
    "id": null,
    "metadata": {},
    "name": "RoleDetailsQuery",
    "operationKind": "query",
    "text": "query RoleDetailsQuery(\n  $id: ID!\n) {\n  role(id: $id) {\n    name\n    ...RoleInfo_role\n    ...RolePermissions_rolePermission\n    id\n  }\n  allPermissions {\n    ...RolePermissions_activePermission\n    id\n  }\n}\n\nfragment RoleInfo_role on Role {\n  id\n  name\n  description\n}\n\nfragment RolePermissions_activePermission on Permission {\n  id\n  name\n}\n\nfragment RolePermissions_rolePermission on Role {\n  id\n  permissions {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "d90fafbed7d18865757c4afb5b2c8e9d";

export default node;
