/**
 * @generated SignedSource<<a6c03ac3926bb7111415568979115fc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LegalContractMutationGroup",
    "kind": "LinkedField",
    "name": "legalContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "LegalContractModifyPayload",
        "kind": "LinkedField",
        "name": "deleteTerm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContract",
            "kind": "LinkedField",
            "name": "legalContract",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractTerm",
                "kind": "LinkedField",
                "name": "terms",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteContractTermModal_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteContractTermModal_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "301fff33722e858de4f03ac7d8df425c",
    "id": null,
    "metadata": {},
    "name": "DeleteContractTermModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation DeleteContractTermModal_Mutation(\n  $id: ID!\n) {\n  legalContract {\n    deleteTerm(input: {id: $id}) {\n      legalContract {\n        id\n        terms {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "08f7448c548d54e039cc36b32a870e68";

export default node;
