import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {Button, Modal} from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";

const DeleteContractTermModal = ({showModal, onClose, selectedTermId, selectedTermType}) => {
    const mutation = graphql`
        mutation DeleteContractTermModal_Mutation($id: ID!) {
            legalContract {
                deleteTerm(input: {id: $id}) {
                    legalContract {
                        id
                        terms {
                            id
                        }
                    }
                }
            }
        }`
    const [commitMutation, isInFlight] = useMutation(mutation)
    const handleDeleteClick = () => {
        commitMutation({variables: {id: selectedTermId}, onCompleted: (data) =>
        {
            toast.success("Term has been deleted");
            onClose();
        }})
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Term?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete Term: {selectedTermType}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteContractTermModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedTermType: PropTypes.string.isRequired,
    selectedTermId: PropTypes.string.isRequired
}
export default DeleteContractTermModal