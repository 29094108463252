/**
 * @generated SignedSource<<e4237c3ade102435313b3a576b830640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworkOwnerDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "networkOwner",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkOwnerInfo_NetworkOwner"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NetworkOwnerDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "networkOwner",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6ec70e211f7d02761ef3847ab66ea15",
    "id": null,
    "metadata": {},
    "name": "NetworkOwnerDetailsQuery",
    "operationKind": "query",
    "text": "query NetworkOwnerDetailsQuery(\n  $id: ID!\n) {\n  networkOwner(id: $id) {\n    name\n    ...NetworkOwnerInfo_NetworkOwner\n    networks {\n      id\n    }\n    id\n  }\n}\n\nfragment NetworkOwnerInfo_NetworkOwner on NetworkOwner {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "0f1bde1d24dd39d0f9a57522cbdaa7ad";

export default node;
