import React from 'react';
import NetworkInfo from "./NetworkInfo";
import LinesOfService from "../lines-of-service/LinesOfService";
import OrganizationsTable from "../organizations/OrganizationsTable";
import Practitioners from "../practitioners/Practitioners";
import OrganizationLog from "../organizations/OrganizationLog";
import ContractsTable from "../contracts/ContractsTable";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {DateCell, LinkCell} from "../../components/common/advance-table/CustomCells";

const contractColumns = [
    {
        accessor: 'name',
        Header: 'Contract Name',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {name, id} = rowData.row.original
            return <LinkCell name={name} to={"/contracts/" + id} hasAvatar />
        }
    },
    {
        accessor: 'organization.name',
        Header: 'Organization',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { organization } = rowData.row.original;
            return <LinkCell name={organization.primaryName} to={"/organizations/" + organization.id} />
        }
    },
    {
        accessor: 'startDate',
        Header: 'Start Date',
        Cell: DateCell
    },
    {
        accessor: 'endDate',
        Header: 'End Date',
        Cell: DateCell
    }
]
const NetworkDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query NetworkDetailsQuery($id: ID!) {
            network(id: $id) {
                name,
                ...NetworkInfo_Network
                contracts {
                    ...ContractsTable_Contract
                }
            }
            allNetworkOwners {
                ...NetworkOwnersFragments_AllNetworkOwnersDropdown
            }
        }
    `, loader.networkDetailsQuery)
    return (
        <>
            <DetailsPageHeader title={data.network.name} iconType={"network-wired"} iconText={"Network"} />
            <NetworkInfo network={data.network} networkOwners={data.allNetworkOwners} />
            {/*<LinesOfService />
            <ContractsTable contracts={data.network.contracts} onNewClick={() => {}} columnsOverride={contractColumns} />
            {/* When we get data fetching ready, uncomment <OrganizationsTable />
            <Practitioners data={practitionersData}/>
            <OrganizationLog /> */}
        </>
    );
};

export default NetworkDetails;
