/**
 * @generated SignedSource<<5bf1a559a3a66fbaf83cf8d3b5c865d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMutationGroup",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "OrganizationModifyPayload",
        "kind": "LinkedField",
        "name": "deleteIdentifier",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationIdentifier",
                "kind": "LinkedField",
                "name": "identifiers",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrganizationIdentifierModalMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteOrganizationIdentifierModalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9219c40e53726d2e2f1e11e05bb8ee8d",
    "id": null,
    "metadata": {},
    "name": "DeleteOrganizationIdentifierModalMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteOrganizationIdentifierModalMutation(\n  $id: ID!\n) {\n  organization {\n    deleteIdentifier(input: {id: $id}) {\n      organization {\n        id\n        identifiers {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "e8b0e0f8108ab3c1667ff5a9fd9e2497";

export default node;
