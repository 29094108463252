import React, {useMemo, useState} from 'react';
import { Card } from 'react-bootstrap';
import {Link, useLoaderData} from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {usePreloadedQuery} from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import NewRoleModal from "./NewRoleModal";
import {useUser} from "../../hooks/useUser";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";

const Roles = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`
            query RolesQuery {
                allRoles 
                {
                    id,
                    name
                }
            }`
    , loader.roleQuery)
    const [showNewRoleModal, setShowNewRoleModal] = useState(false);
    const {hasPermission} = useUser()
    const columns = useMemo(() => [
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { name, id } = rowData.row.original;
                return <LinkCell to={"/roles/" + id} hasAvatar name={name} />
            }
        }
    ], []);
    return (<>
            <AdvanceTableWrapper
                columns={columns}
                data={data.allRoles}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <IndexTableHeader table 
                                          name={"Roles"} 
                                          onNewClick={() => {setShowNewRoleModal(true)}}
                                          showNew={hasPermission("Roles.Create")}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter rowCount={data.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewRoleModal showModal={showNewRoleModal} onHide={() => {setShowNewRoleModal(false)}} />
        </>
    );
};

export default Roles;
