/**
 * @generated SignedSource<<9ccd24f6e8f34fb312d14c2dca79ad0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMutationGroup",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "NetworkOwnerModifyPayload",
        "kind": "LinkedField",
        "name": "delete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NetworkOwner",
            "kind": "LinkedField",
            "name": "networkOwner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationDetails_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationDetails_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5151c7210df2f30e493a36a4dc17f0ae",
    "id": null,
    "metadata": {},
    "name": "OrganizationDetails_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationDetails_DeleteMutation(\n  $id: ID!\n) {\n  organization {\n    delete(input: {id: $id}) {\n      networkOwner {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "cfc3407f11370bec199b2975b2105be4";

export default node;
