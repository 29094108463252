import React from 'react';
import RoleInfo from "./RoleInfo";
import {useLoaderData} from "react-router-dom";
import RolePermissions from "./RolePermissions";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";

const RoleDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`
            query RoleDetailsQuery($id: ID!) {
                role(id: $id) {
                    name
                    ...RoleInfo_role
                    ...RolePermissions_rolePermission
                }
                allPermissions {
                    ...RolePermissions_activePermission
                }
            }`
    , loader.roleDetails)
    return (
        <>
            <DetailsPageHeader title={data.role.name} iconText={"Role"} iconType={"key"} />
            <RoleInfo role={data.role} />
            <RolePermissions role={data.role} permissions={data.allPermissions}/>
        </>
    );
};

export default RoleDetails;
