import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {UserContext} from "../context/Context";
import {toast} from "react-toastify";
import {getItemFromStore, removeItemFromStore, setItemToStore} from "../helpers/utils";
const UserProvider = ({children}) => {
    const [user, setUser] = useState();
    const [firstAttemptSettled, setFirstAttemptSettled] = useState(false);
    useLayoutEffect(() => {
        const possibleUserData = getItemFromStore("user", null);
        if(possibleUserData === null) {
            fetch("/account/user").then(r => {
                return r.json();
            }).then((userData) => {
              setUser(userData);
              setItemToStore("user", JSON.stringify(userData));
            }, () => {}).finally(() => {
                setFirstAttemptSettled(true);
            })
        } else {
            setUser(possibleUserData);
            setFirstAttemptSettled(true);
        }
    },[])
    useEffect(() => {
        window.addEventListener('storage', handleLocalStorageChange);
        return () => {
          window.removeEventListener('storage', handleLocalStorageChange)  
        };
    })
    const handleLocalStorageChange = () => {
        const userData = getItemFromStore("user",null);
        setUser(userData);
    }
    const hasPermission = (permission) => {
        if(!user || !user.permissions) {
            return false;
        }
        return user.permissions.includes(permission)
    }
    const login = async (username, password) => {
       let response = await fetch("/account/login", {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
        if(response.ok) {
            const userData = await response.json();
            setItemToStore("user", JSON.stringify(userData));
            setUser(userData);
            return userData;
        }
        else {
            toast.error("Login failed. Check your username and password")
            return null;
        }
    }
    const logout = () => {
        removeItemFromStore("user");
        setUser();
    }
    if(!firstAttemptSettled) {
        return <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
        />
    }
    return <UserContext.Provider value={{user, hasPermission, login, logout, setUser}}>
        {children}
    </UserContext.Provider>
}
const useUser = () => {
    const context = useContext(UserContext)
    if(context === undefined) {
        throw new Error('useUser must be used within an UserProvider')
    }
    return context;
}

export {useUser, UserProvider}