import React from 'react';
import { Card } from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return <LinkCell to={"/networks/" + id} name={name} hasAvatar />
    }
  },
  {
    accessor: 'networkOwner.name',
    Header: 'Network Owner',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { networkOwner } = rowData.row.original;
      return <LinkCell to={"/networks/" + networkOwner.id} name={networkOwner.name} />
    }
  }
];

const Networks = () => {
  const loader = useLoaderData();
  const data = usePreloadedQuery(graphql`
    query NetworksQuery {
      allNetworks {
        id
        name
        networkOwner {
          id
          name
        }
      }
      allNetworkOwners {
        ...NetworkOwnersFragments_AllNetworkOwnersDropdown
      }
    }
  `, loader.networksQuery)
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data.allNetworks}
      sortable
      pagination
      perPage={25}
    >
      <Card className="mb-3">
        <Card.Header>
          <IndexTableHeader name={"Networks"} onNewClick={() => {}} showNew={false} table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data.length}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Networks;
