import React, {useState} from 'react';
import {practitionersData} from "../../data/practitioners/practitionersData";
import {Card, Col, Row} from "react-bootstrap";
import Identifiers from "../../components/identifiers/Identifiers";
import {useLoaderData} from "react-router-dom";
import OrganizationInfo from "./OrganizationInfo";
import Addresses from "../../components/addresses/Addresses";
import OrganizationContacts from "./contacts/OrganizationContacts";
import ContactPoints from "../../components/contactpoints/ContactPoints";
import Locations from "../locations/Locations";
import OrganizationLog from "./OrganizationLog";
import RelatedOrganizations from "./RelatedOrganizations";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../hooks/useUser";
import ContractsTable from "../contracts/ContractsTable";
import {DateCell, LinkCell} from "../../components/common/advance-table/CustomCells";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
import OrganizationIdentifiers from "./identifiers/OrganizationIdentifiers";
import OrganizationContactPoints from "./contactpoints/OrganizationContactPoints";
import OrganizationAddresses from "./addresses/OrganizationAddresses";

const fakeOrgData = {
    name: "Conway Regional",
    practitioners: practitionersData.filter(p => p.organization === 'Conway Regional'),
    parentOrganization: {
        name: "<<NONE>>"
    },
    contracts: [
        {
            id: 1,
            effectiveFrom: '2/5/2019',
            effectiveTo: '<current>',
            network: 'Vantos',
            linesOfService: 'Arkansas Medicare, Arkansas Medicaid',
            contractedOrganization: 'Conway Regional'
        }
    ],
    relatedOrganizations: [
        {
            id: 1,
            name: 'Conway Regional System',
            effectiveFrom: '2/5/2019',
            effectiveTo: '<current>',
            relationship: 'Parent Company'
        }
    ]
}

const contractColumns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { network } = rowData.row.original;
            return <LinkCell name={network.name} to={"/networks/" + network.id} />
        }
    },
    {
        accessor: 'startDate',
        Header: 'Start Date',
        Cell: DateCell
    },
    {
        accessor: 'endDate',
        Header: 'End Date',
        Cell: DateCell
    }
]
const OrganizationDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`query OrganizationDetailsQuery($id: ID!) {
                organization(id: $id) {
                    id
                    primaryName
                    ...OrganizationInfo_Organization
                    contacts {
                        ...OrganizationContacts_Contacts
                    }
                    identifiers {
                        ...OrganizationIdentifiers_Identifiers
                    }
                    contracts {
                        ...ContractsTable_Contract
                    }
                    contactPoints {
                        ...OrganizationContactPoints_ContactPoints
                    }
                    addresses {
                        ...OrganizationAddresses_Addresses
                    }
                }
                allNetworkOwners {
                    ...NetworkOwnersFragments_AllNetworkOwnersDropdown
                }
                allIdentifierTypes {
                    ...Fragments_AllIdentifierTypes
                }
                allIdentifierUseCodes {
                ...Fragments_AllIdentifierUseCodes
                }
                allContactPointSystemCodes {
                    ...Fragments_AllContactPointSystemCodesDropdown
                }
                allContactPointUseCodes {
                    ...Fragments_AllContactPointUseCodesDropdown
                }
                allAddressUseCodes {
                    ...Fragments_AllAddressUseCodesDropdown
                }
            }
        `, loader.organizationDetails)
    const deleteMutation = graphql`
        mutation OrganizationDetails_DeleteMutation($id: ID!) {
            organization {
                delete(input: {id: $id}) {
                    networkOwner {
                        id
                    }
                }
            }
        }
    `
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconType={"building"} iconText={"Organization"} title={data.organization.primaryName} />
            <Row>
                <Col>
                    <OrganizationInfo organization={data.organization} networkOwners={data.allNetworkOwners}/>
                </Col>
            </Row>
            {/*<Row>
                <Col>
                    <RelatedOrganizations organizationData={fakeOrgData.relatedOrganizations}/>
                </Col>
            </Row>*/}
            <Row>
                {hasPermission("OrganizationIdentifiers.Read") &&
                    <Col md={6}>
                        <OrganizationIdentifiers identifiers={data.organization.identifiers}
                                                 identifierTypes={data.allIdentifierTypes}
                                                 identifierUseCodes={data.allIdentifierUseCodes}
                                                 organizationId={data.organization.id}
                        />
                    </Col>
                }
                {hasPermission("OrganizationAddresses.Read") &&
                    <Col md={6}>
                        <OrganizationAddresses organizationId={data.organization.id} 
                                               addresses={data.organization.addresses} 
                                               addressUseCodes={data.allAddressUseCodes}
                        />
                    </Col>
                }
            </Row>
            <Row>
                {hasPermission("OrganizationContactPoints.Read") &&
                    <Col md={6}>
                        <OrganizationContactPoints contactPoints={data.organization.contactPoints} 
                                                   organizationId={data.organization.id} 
                                                   contactPointSystemCodes={data.allContactPointSystemCodes} 
                                                   contactPointUseCodes={data.allContactPointUseCodes}
                        />
                    </Col>
                }
                {hasPermission("OrganizationContacts.Read") &&
                    <Col md={6}>
                        <OrganizationContacts contacts={data.organization.contacts} organizationId={data.organization.id} />
                    </Col>
                }
            </Row>
            {/*
            <ContractsTable contracts={data.organization.contracts} columnsOverride={contractColumns} onNewClick={() => {}} />
            <Locations/>
            <OrganizationLog/>*/}
            {hasPermission("Organizations.Delete") && 
                <DeletePanel mutation={deleteMutation} 
                             redirectUrl={"/organizations"} 
                             thingToDelete={"Organization"} id={data.organization.id}>
                    In order to delete an Organization, there must be no related data
                </DeletePanel>}
        </>
    );
};

export default OrganizationDetails;
